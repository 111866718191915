<template>
  <v-container>
    {{ $store.commit('setMetadata', $t('nav.Vorlagen' )) }}
    <div v-if="!$store.state.loading && templates">
      <v-row justify="center">
        <v-col cols="12" class="col-md-8">
          <v-row>
            <v-col v-if="hasWrite()" cols="auto">
              <template-creation-dialog/>
            </v-col>
            <v-col>
              <v-text-field
                v-model="searchText"
                solo
                dense
                hide-details
                clearable
                label="Suche"
                prepend-inner-icon="mdi-magnify"
                @click:clear="searchText = ''"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <loading-spinner :loading="loading"/>
      <v-row justify="center">
        <v-col cols="12" class="col-md-8">
          <div>
            <v-list>
              <v-list-item
                v-for="(template, index) in filterResults(searchText)"
                :key="template.id"
                @click="
                  href('/templates/' + template.id)
                "
              >
                <v-list-item-content>
                  <v-list-item-title v-text="template.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="renderSubtitle(template)"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="hasWrite()">
                  <clone-template-dialog :template="template" />
                </v-list-item-action>
                <v-list-item-action v-if="hasWrite()">
                  <v-btn icon @click="href('/templates/' + template.id + '/info')">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </v-list-item-action>

              </v-list-item>
            </v-list>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="col-md-8">
          <Pagination
            :current-page.sync="currentPage"
            :items="templates"
            :search-text="searchText"/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import TemplateCreationDialog from '../components/template/dialog/TemplateCreationDialog'
import DeleteTemplateDialog from '../components/template/dialog/DeleteTemplateDialog'
import CloneTemplateDialog from '../components/template/dialog/CloneTemplateDialog'
import RenameDocumentDialog from '@/components/form/dialog/RenameDocumentDialog'
import LoadingSpinner from '../components/utils/LoadingSpinner'
import { mapGetters } from 'vuex'
import { format, register } from 'timeago.js'
import de from 'timeago.js/lib/lang/de'
import en from "timeago.js/lib/lang/en_US";
import Pagination from "@/components/utils/Pagination";
import CreateNewRevisionDialog from "../components/form/dialog/CreateNewRevision.vue";

export default {
  name: 'TemplateList',
  components: {
    CreateNewRevisionDialog,
    Pagination,
    LoadingSpinner,
    CloneTemplateDialog,
    DeleteTemplateDialog,
    TemplateCreationDialog,
    RenameDocumentDialog
  },
  beforeRouteEnter: function (to, from, next) {
    next(vm => vm.fetchDocs())
  },
  data: function () {
    return {
      currentPage: 1,
      dialog: false,
      loading: true,
      searchText: '',
      templates: []
    }
  },
  computed: {
    ...mapGetters(['config', 'authToken']),
  },
  mounted () {
    this.$store.commit('sync')
    this.fetchDocs()
  },
  methods: {
    href: function (dest) {
      this.$router.push(dest)
    },
    delTemplate () {
      this.fetchDocs()
    },
    fetchDocs: function () {
      this.$templateController.getDocumentList()
        .then(res => {
          this.templates = res.data
          this.loading = false
        })
        .catch(res => {
          console.error(res)
          throw res
        })
    },
    renderSubtitle: function (form) {
      register('de', de)
      register('en', en)
      if (localStorage.getItem('lang') === "de")
        return "Bearbeitet " + format(form.updated, 'de') + " von " + form.editorName
      else
        return "Changed " + format(form.updated, 'en') + " by " + form.editorName
    },
    filterResults(searchText) {
      const lowerSearchText = searchText.toLowerCase();
      const searchFields = ["name", "creatorName", "editorName"];
      return this.templates.filter(template => {
        return searchFields.some(field => {
          const value = template[field];
          return typeof value === "string" && value.toLowerCase().includes(lowerSearchText)
        });
      }).slice((this.currentPage - 1) * 10, (this.currentPage - 1) * 10 + 10)
    },
    hasWrite: function () {
      return this.$store.state.auth.groups.includes("write")
    }
  }
}
</script>

<style scoped></style>
