<template>
  <div>
    <template>
      <v-row justify="center">
        <v-dialog v-model="showNoSaveConfirm" max-width="500px" persistent>
          <v-card>
            <v-card-title class="headline">
              Ungespeicherte Änderungen!!
            </v-card-title>
            <v-card-text
            >Sie haben noch ungespeicherte Änderungen. Bitte speichern Sie Ihre Änderungen.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="cancelRoute">
                Abbrechen
              </v-btn>
              <v-btn color="error" text @click="confirmRoute">
                Ohne Speichern fortfahren
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <template v-if="form && !loadingChangelogs">
      <v-container class="container">
        <div :class="formStyleClass">
          <v-bottom-navigation
            v-if="changeLogFileMode"
            app
            height="auto">
            <v-slider
              v-model="selectedDateIndex"
              :hint="'Selected Date: ' + formDates[selectedDateIndex]"
              :max="formDates.length - 1"
              :min="0"
              :step="1"
              class="ma-16"
              persistent-hint
              @input="updateSelectedDate"
            >
            </v-slider>
          </v-bottom-navigation>

          <v-alert v-if="noChangelogFiles" type="info">
            {{ $t('document.noChangelogFilesFound') }}
          </v-alert>

          <v-alert v-if="onlyOneChangelogFile" type="info">
            {{ $t('document.onlyOneChangelogFileFound') }}
          </v-alert>

          <FormChild
            v-if="!loadingChangelogs"
            :changelog-file-mode="changeLogFileMode"
            :changelog-mode="showChangelog"
            :fetch="fetch"
            :form="form"
            :formStyleClass="formStyleClass"
            :showMetaHeader="showMetaHeader">
          </FormChild>

        </div>
        <div v-if="showChangelog && Object.keys(formChangelog).length > 0"
             :class="['changelogStyleClass', 'changelog']">
          <FormChangelog :changelog="formChangelog" @mapChangelogToFile="mapChangelogToFile"></FormChangelog>
        </div>

        <div v-else-if="showChangelog && Object.keys(formChangelog).length === 0" :class="changelogStyleClass">
          <div class="content">
            <h1>Changelog</h1>
            <p>Es wurden noch keine Veränderungen am Dokument vorgenommen.</p>
          </div>
        </div>
      </v-container>
    </template>
    <template v-else>
      <v-container class="container">
        <div :class="formStyleClass">
          <v-sheet
            class="ma-9"
          >
            <v-skeleton-loader

              max-width="300"
              type="card, list-item"
            ></v-skeleton-loader>
          </v-sheet>
        </div>
      </v-container>
    </template>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.full-screen {
  width: 100%;
  height: 100%;
}

.half-screen {
  width: 50%;
  height: 100%;
}

</style>

<script>
import StructureEditor from "../components/template/StructureEditor";
import ValueEditor from "../components/form/ValueEditor";
import FormChangelog from "@/components/form/FormChangelog.vue";
import FormChild from "../components/form/Form";
import {mapGetters} from "vuex";
import pdfExport from '@/components/export/pdf-export';

export default {
  name: "Form",
  components: {
    ValueEditor,
    StructureEditor,
    FormChangelog,
    FormChild
  },
  computed: {
    ...mapGetters([
      "config",
      "saveEnabled",
      "saveEvent",
      "additionalNavigationEntries",
      "printIntended",
      "changelogMode",
      "changelogFileMode",
      "revisionMode",
      "requiredFieldsDetail"
    ]),
  },
  watch: {
    requiredFieldsDetail(value) {
      if (value) this.$router.push(`${this.$route.params.id}/required-fields-detail`);
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate triggered');
    if (to.path === from.path) {
      next();
      return;
    }
    if (!this.saveEnabled) next();
    if (this.saveEnabled && this.noSaveConfirmed) next();
    this.requestLeaveConfirmation(to);
    console.log('beforeRouteUpdate here');
    next(false);
  },
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave triggered');
    let shouldLeave = false;

    if (to.path === from.path) shouldLeave = true;
    if (!this.saveEnabled) shouldLeave = true;
    if (this.saveEnabled && this.noSaveConfirmed) shouldLeave = true;

    if (shouldLeave) {
      this.$store.commit("clearAdditionalNavigationEntries");
    } else {
      this.requestLeaveConfirmation(to);
    }
    next(shouldLeave);
  },
  data: function () {
    return {
      edit: false,
      form: null,
      showNoSaveConfirm: false,
      noSaveConfirmed: false,
      targetRoute: "/",
      loadingChangelogs: true,
      showChangelog: false,
      changeLogFileMode: false,
      formStyleClass: "full-screen",
      changelogStyleClass: "half-screen changelog",
      formChangelog: {},
      selectedDateIndex: 0,
      selectedDate: "",
      formDates: [],
      showMetaHeader: true,
      noChangelogFiles: false,
      onlyOneChangelogFile: false,
    };
  },
  async mounted() {
    console.log("Component mounting.")
    this.$store.commit("setEye", true);
    this.$store.commit("sync");

    if (this.$route.path.includes('/doc-history')) {
      await this.activateChangelogFiles(true)
    } else if (this.$route.path.includes('/changelog')) {
      await this.activateChangelog()
    } else {
      await this.fetch(this.$route.params.id, this.$route.params.revisionId)
      this.$store.commit("setSaveVisible", true);
      this.$store.commit("setRequiredFieldsDetailEnabled", true);
    }
  },
  destroyed() {
    this.$store.commit("setMetadata", null);
    this.$store.commit("setRequiredFieldsDetailEnabled", false);
  },
  methods: {
    mapChangelogToFile(args) {
      let fieldId = args[0]
      let changelogTimestamp = args[1]

      if (changelogTimestamp) {
        this.form = this.formChangelogFiles.filter(item => item.updated === changelogTimestamp)[0];
      }
      this.scrollToField(fieldId);
    },
    scrollToField(fieldId) {
      console.log("scrollToField", fieldId);
      const fieldElement = document.getElementById(fieldId);
      if (fieldElement) {
        fieldElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Monate sind nullbasiert
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} - ${hours}:${minutes}`;
    },
    updateSelectedDate() {
      this.selectedDate = this.formDates[this.selectedDateIndex];
      this.form = this.formChangelogFiles[this.selectedDateIndex]
    },
    updateNavigationEntries: function (form) {
      let entries = [];
      for (let i = 0; i < form.sections.length; i++) {
        let section = form.sections[i];
        entries.push({name: section.name, href: "#" + i});

        for (let j = 0; j < section.fields; j++) {
          let field = section.fields[j];
          if (field.type === "section")
            entries.push({name: field.name, href: "#" + i + "-" + j});
        }
      }

      this.$store.commit("setAdditionalNavigationEntries", entries);
    },
    async activateChangelog() {
      await this.fetch(this.$route.params.id)
      this.loadingChangelogs = true;
      await this.fetchChangelog(this.$route.params.id);
      await this.activateChangelogFiles(false);
      this.formStyleClass = "half-screen";
      this.showChangelog = true;
      this.loadingChangelogs = false;
    },
    async activateChangelogFiles(slider) {
      await this.fetchAllChangelogFiles(this.$route.params.id);

      this.formChangelogFiles.forEach((form) => {
        this.formDates.push(this.formatDate(form.updated))
      })

      if (slider) {
        this.selectedDate = this.formDates[this.formDates.length - 1];
        this.selectedDateIndex = this.formDates.length - 1;
        this.form = this.formChangelogFiles[this.selectedDateIndex];
        this.showMetaHeader = false;
        this.changeLogFileMode = true;
      }
      this.loadingChangelogs = false;

      if (!this.form) {
        this.form = {};
        this.noChangelogFiles = true;
      }

      if (this.formChangelogFiles.length === 1) {
        this.onlyOneChangelogFile = true;
      }
    },
    fetch: function (id, revId) {
      this.loadingChangelogs = true;
      if (revId) {
        this.$formController.getDocumentByRevId(id, revId)
          .then(res => {
            this.form = res.data;
            this.updateNavigationEntries(this.form)
            return res.data;
          })
      } else {
        this.$formController.getDocument(id)
          .then(res => {
            this.form = res.data;
            this.updateNavigationEntries(this.form)
            return res.data;
          })
      }
      this.loadingChangelogs = false;
    },
    fetchAllChangelogFiles: async function (id) {
      await this.$formController.getDocumentChangelogFiles(id)
        .then(res => {
          this.formChangelogFiles = res.data;
          return res.data;
        })
        .catch(err => {
          console.log(err)
        })
    },
    fetchChangelog: async function (id) {
      await this.$formController.getDocumentChangelog(id)
        .then(res => {
          try {
            this.formChangelog = JSON.parse(res.data);
          } catch (err) {
            console.log(err);
            this.formChangelog = [];
          }
          return res.data;
        })
        .catch(err => {
          console.log(err)
        })
    },
    pdf: function () {
      pdfExport(this.form);
    },
    save: function () {
      return (
        this.$formController.updateDocument(this.form)
          // refetch to prevent conflicts on next save
          .then(() => this.fetch(this.$route.params.id))
          // generate success message
          .then(() => {
            this.$store.commit("setSaveEnabled", false);
            this.$store.commit("setSaveEvent", false);
            return {
              active: true,
              color: "success",
              message: "Erfolgreich gespeichert."
            };
          })
          // generate error message // todo better description
          .catch(err => {
            this.$store.commit("setSaveEvent", false);
            return {
              active: true,
              color: "error",
              message: err
            };
          })
          .then(alert => this.$store.commit("setSyncAlert", alert))
      ); // send alert
    },
    requestLeaveConfirmation: function (to) {
      this.showNoSaveConfirm = true;
      this.noSaveConfirmed = false;
      this.targetRoute = to.fullPath;
    },
    cancelRoute: function () {
      this.noSaveConfirmed = false;
      this.showNoSaveConfirm = false;
    },
    confirmRoute: function () {
      this.noSaveConfirmed = true;
      this.showNoSaveConfirm = false;
      this.$router.push(this.targetRoute);
    }
  }
};
</script>

