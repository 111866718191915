import Vue from "vue";
import VueRouter from "vue-router";
import Form from "../views/Form.vue";
import FormList from "../views/FormList";
import Login from "../views/Login";
import TemplateBuilder from "../views/TemplateBuilder";
import TemplateList from "../views/TemplateList";
import SettingsView from "../views/SettingsView";
import UserView from '@/views/UserView'
import UserListView from '@/views/UserListView'
import GroupListView from '@/views/GroupListView'
import RevisionCompare from "@/views/RevisionCompare.vue";
import RequiredFieldsDetail from '@/views/RequiredFieldsDetail.vue'
import FormInfoPage from '@/views/FormInfoPage.vue'
import TemplateInfoPage from '@/views/TemplateInfoPage.vue'
import Me from '@/views/Me.vue'

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsView,
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserView,
  },
  {
    path: "/settings/users",
    name: "User List",
    component: UserListView,
  },
  {
    path: "/settings/groups",
    name: "Group List",
    component: GroupListView,
  }
  ,
  {
    path: "/forms/:id/changelog",
    name: "Changelog",
    component: Form,
  }
  ,
  {
    path: "/forms/:id/doc-history",
    name: "Document History",
    component: Form,
  }
  ,
  {
    path: "/forms/:id/revision",
    name: "Revision Compare",
    component: RevisionCompare,
  }
  ,
  {
    path: "/forms/:id/required-fields-detail",
    name: "Required Fields Detail",
    component: RequiredFieldsDetail,
  },
  {
    path: "/forms/:id/info",
    name: "Form Details Page",
    component: FormInfoPage,
  },
  {
    path: "/forms/:id/revisions/:revisionId",
    name: "Form Page with Revision",
    component: Form,
  },
  {
    path: "/templates/:id/info",
    name: "Template Details Page",
    component: TemplateInfoPage,
  },
  {
    path: '/forms/:id',
    name: "Form",
    component: Form
  },
  {
    path: '/',
    name: "Me",
    component: Me
  },
  {
    path: '/forms',
    name: "FormList",
    component: FormList
  },
  {
    path: '/templates',
    name: "TemplateList",
    component: TemplateList
  },
  {
    path: '/templates/:id',
    name: "TemplateBuilder",
    component: TemplateBuilder
  },
  {
    path: '*',
    redirect: '/'
  }
];

export default function () {
  let routes = baseRoutes;

  return new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        // if has a hash...
        return {selector: to.hash, offset: {x: 0, y: 70}}; // scroll to the element
      }
    },
  });
}
