<template>
  <v-dialog v-model="dialog" width="500">
    <template #activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="isValid">
        <v-card-title>{{user.username}}&nbsp;{{ $t('settings.password.edit') }}</v-card-title>
        <v-col>
          <v-text-field
            type="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            v-model="newPassword"
            :rules="rules"
            :label="$t('settings.password.label')"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
          <v-checkbox v-model="permanent" :label="$t('settings.password.permanent')" ></v-checkbox>
        </v-col>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
          <v-btn color="primary" :loading="loading" :disabled="!isValid" text @click="setPassword">{{ $t('actions.change') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>


export default {
  name: 'EditUserDialog',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show1: false,
      dialog: false,
      isValid: false,
      rules: [],
      loading: false,
      newPassword: '',
      permanent: true
    }
  },
  methods: {
    setPassword() {
      this.isValid = false
      this.loading = true
      this.$userController.setPassword(this.user.username, this.newPassword, this.permanent)
        .then(res => (res.data))
        .finally( () => {
          this.newPassword = ''
          this.dialog = false
          this.loading = false
          this.$emit("password-changed")
        })
    },
    cancel() {
      this.dialog = false
      this.newPassword = ''
    }
  },
  mounted() {
    this.$userController.getPasswordPolicy().then(policies => {
      this.rules = []

      // policies.RequireLowercase = true
      // policies.RequireUppercase = true
      // policies.RequireNumbers = true
      // policies.RequireSymbols = true

      if(policies.MinimumLength) this.rules.push(value => value.length >= policies.MinimumLength || this.$t('rules.minimumLength'))
      if(policies.RequireLowercase) this.rules.push(value => /[a-z]/.test(value) || this.$t('rules.requireLowercase'))
      if(policies.RequireUppercase) this.rules.push(value => /[A-Z]/.test(value) || this.$t('rules.requireUppercase'))
      if(policies.RequireNumbers) this.rules.push(value => /\d/.test(value) || this.$t('rules.requireNumbers'))
      if(policies.RequireSymbols) this.rules.push(value => /[^$*.\[\]{}()?"!@#%&\/,><':;|_~`=+-]/.test(value) || this.$t('rules.requireSymbols'))
    })
  }
}
</script>

<style scoped></style>
